<template>
    <BT-Blade-Item
        bladeName="batch"
        :bladesData="bladesData"
        :canSave="useAnotherBatch != null"
        :customBladeData="customBladeData"
        :hideBladeHeader="customBladeData != null"
        hideSaveAndClose
        navigation="batches"
        :onGetSave="getSave"
        :onNew="getNew"
        title="Batch"
        :tourStatus="useAnotherBatch == null ? 'options' : (useAnotherBatch == true ? 'convert' : null)"
        @saved="$emit('saved')"
        @sourceChanged="updateBlade">
        <template v-slot="{ item, data }">
            <v-row v-if="useAnotherBatch == null && data.isNew" no-gutters class="pt-2">
                <v-col cols="6">
                    <v-btn @click="useAnotherBatch = false" height="75" width="100%" class="v-step-batch-new">
                        New Batch
                    </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn @click="useAnotherBatch = true" height="75" width="100%" class="v-step-batch-convert">
                        Convert Batch
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-else no-gutters>
                <v-col v-if="data.isNew" cols="12">
                    <BT-Field-Select
                        class="v-step-batch-location"
                        isEditing
                        itemText="locationName"
                        label="Location"
                        navigation="locations"
                        v-model="item.locationID" />
                    
                    <v-divider class="my-3" />
                </v-col>
                <v-col v-if="data.isNew && useAnotherBatch === true" cols="12">
                    <div>
                        <BT-Field-Select
                            class="v-step-batch-batch"
                            isEditing
                            label="Batch Used"
                            navigation="batches"
                            :onFilter="filterBatches"
                            :params="{ filterBy: 'Not Pending', includeDetails: false }"
                            v-model="item.originalBatchID">
                            <template v-slot:item="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.product.productName }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.batchcode }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <span class="mr-3">DOM: {{  item.dom | toShortDate }}</span>
                                        <span>EXP: {{ item.exp | toShortDate }}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                            <template v-slot:selection="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.product.productName }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.batchcode }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <span class="mr-3">DOM: {{  item.dom | toShortDate }}</span>
                                        <span>EXP: {{ item.exp | toShortDate }}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </BT-Field-Select>

                        <BT-Field-Number
                            class="v-step-batch-used"
                            fieldClass="my-3"
                            hide-details
                            isEditing
                            label="Quantity Used"
                            v-model.number="item.quantityUsed" />

                        <v-divider class="my-2" />

                    </div>
                </v-col>
                <v-col cols="12">
                    <BT-Select-List-Box
                        v-model="item.productID"
                        class="v-step-batch-product"
                        label="Product Produced"
                        :isEditing="data.isNew"
                        :isSelecting="data.isNew"
                        itemText="productName"
                        itemValue="id"
                        height="400"
                        navigation="products"
                        :params="{ filterBy: 'Manufactured' }"
                        :searchProperties="['productName', 'abbreviation']"
                        @change="updateBatchcode(item)">
                        <template v-slot="{ item }">
                            <v-list-item-avatar>
                                <v-icon color="primary">mdi-cube</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-subtitle>
                                {{ item.productName }}
                            </v-list-item-subtitle>
                        </template>
                    </BT-Select-List-Box>

                    <v-divider class="my-3" />
                </v-col>

                <v-col cols="6">
                    <BT-Square-Check
                        class="v-step-batch-pending"
                        :disabled="useAnotherBatch === true"
                        :isEditing="data.isNew"
                        label="Pending"
                        outlined
                        v-model="item.isPending" />
                </v-col>
                <v-col cols="6">
                    <BT-Square-Check
                        class="v-step-batch-onhold"
                        :disabled="useAnotherBatch === true || item.isPending"
                        :isEditing="data.isNew || (data.isEditing && item.onHold)"
                        label="On Hold" 
                        outlined
                        v-model="item.onHold" />
                </v-col>

                <v-col cols="6">
                    <BT-Square-Number
                        class="v-step-batch-goal"
                        :isEditing="data.isNew || data.isEditing"
                        label="Quantity Goal"
                        v-model.number="item.quantityGoal" />
                </v-col>
                <v-col cols="6">
                    <BT-Square-Number
                        class="v-step-batch-made"
                        :isEditing="data.isNew || data.isEditing"
                        label="Quantity Made"
                        v-model.number="item.quantity" />
                </v-col>

                <v-col v-if="!data.isNew && item.originalBatchID != null" cols="12">
                    <BT-Field-Select
                        :isEditing="false"
                        label="Sourced From Batch"
                        navigation="batch-archives"
                        itemText="batchcode"
                        single
                        v-model="item.originalBatchID" />

                    <v-divider class="my-3" />
                </v-col>

                <v-col cols="12">
                    <BT-Field-String
                        :isEditing="data.isEditing || data.isNew"
                        label="Batchcode"
                        v-model="item.batchcode" />
                </v-col>
                
                <v-col cols="12">
                    <BT-Field-Date
                        @change="updateBatchcode(item)"
                        :isEditing="data.isEditing || data.isNew"
                        label="Date Of Manufacture"
                        v-model="item.dom" />
                </v-col>
                
                <v-col cols="12">
                    <BT-Field-Date
                        :isEditing="data.isEditing || data.isNew"
                        label="Date Of Expiry"
                        v-model="item.exp" />
                </v-col>

                <v-col v-if="!data.isNew" cols="12">
                    <BT-Btn-Row>
                        <BT-Btn
                            bladeName="batch-tracking"
                            buttonClass="primary"
                            :id="item.id"
                            label="Track"
                            icon="mdi-radar" />
                    </BT-Btn-Row>
                </v-col>

                <v-col v-if="!data.isNew" cols="12">
                    <BT-Preload label="Activity Log">
                        <v-subheader>Activity Log</v-subheader>
                        <BT-Blade-Items
                            :canExportCSV="false"
                            :canSelect="false"
                            hideActions
                            hideBackButton
                            hideBladeHeader
                            hideHeader
                            navigation="batch-adjustment-archives"
                            :params="{ batchID: item.id }"
                            showList>
                            <template v-slot:listItem="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span>
                                            <BT-Entity
                                                alternateText="Someone"
                                                inline
                                                navigation="users"
                                                :itemValue="item.userID"
                                                single
                                                textFilter="toUserLine"
                                                useLocalCache />
                                        </span>
                                        <span v-if="item.adjustmentType == 'Waste'" class="mr-1">wasted</span>
                                        <span v-else-if="item.adjustmentType == 'Add'" class="mr-1">added</span>
                                        <span v-else-if="item.adjustmentType == 'Remove'" class="mr-1">removed</span>
                                        <span v-else-if="item.adjustmentType == 'Hold'" class="mr-1">held back</span>
                                        <span v-else-if="item.adjustmentType == 'OnHold'" class="mr-1">held all</span>
                                        <span class="mr-1">{{ item.quantity | toDisplayNumber }}</span>
                                        <span>unit{{ item.quantity != 1 ? 's' : '' }} at</span>
                                        <span>
                                            <BT-Entity
                                                navigation="locations"
                                                inline
                                                :itemValue="item.locationID"
                                                itemText="locationName"
                                                single
                                                useLocalCache />
                                        </span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ item.createdOn | toLongDateAndTime }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </BT-Blade-Items>
                    </BT-Preload>
                </v-col>
            </v-row>
        </template>
    </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Batch-Blade',
    components: {
        BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
    },
    data: function() {
        return {
            useAnotherBatch: null,
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
        customBladeData: {
            type: Object,
            default: null
        }
    },
    methods: {
        filterBatches(list) {
            list.sort(firstBy(x => x.product.sortNumber));
            return list;
        },
        getNew(bladeData) {
            var item = { 
                locationID: null, 
                dom: this.getToday(), 
                exp: this.getToday(), 
                quantity: 0, 
                quantityGoal: 0, 
                quantityUsed: 0, 
                originalBatchID: null 
            };
            
            if (bladeData != null && bladeData.data != null && bladeData.data.locationID != null) {
                item.locationID = bladeData.data.locationID;
            }

            if (item.locationID == null) {
                item.locationID = this.selectedLocationID();
            }

            return item;
        },
        getSave(bladeData, item) {
            if (item.isPending) {
                item.goal = 0;
                item.onHold = false;
                item.originalBatchID = null;
                item.quantityUsed = 0;
            }
            else if (this.useAnotherBatch === false && item.id == null) {
                item.originalBatchID = null;
                item.quantityUsed = 0;
            }


            return item;
        },
        nestVal(item, path, filter) {
            var t = item;

            if (path != null) {
                t = this.getNestedValue(item, path);
            }
            
            if (filter != null) {
                return this.$options.filters[filter](t);
            }
            else {
                return t;
            }
        },
        async updateBatchcode(item) {
            if (item.productID == null || item.dom == null) {
                item.batchcode = null;
                item.exp = null;
            }
            else {
                var prodList = await this.$BlitzIt.store.getAll('products', { filterBy: 'Manufactured' });
                var prod = prodList.find(x => x.id == item.productID);
                
                if (prod != null) {
                    item.batchcode = prod.batchcodeFormat != null ? this.$BlitzIt.auth.formTZ(item.dom, prod.batchcodeFormat) : null;
                    item.exp = prod.expiryDays > 0 ? this.$BlitzIt.auth.formUTC(item.dom, null, prod.expiryDays) : null;
                }
            }
        },
        updateBlade(d) {
            if (d != null && d.data.id === 'new') {
                this.useAnotherBatch = null;
            }
            else {
                this.useAnotherBatch = false;
            }
        }
    }
}
</script>